module.exports = {
    CartInfo: {
        cartItems: 0,
    },

    Home: {
        homeTab: "BEST_SELLERS"
    }
};


//Home:
    //HomeTab: "MOST_RECENT, BEST_SELLERS, HIGHLIGHTED" 