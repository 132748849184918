//TODO THIS IS A TEST  DELETE LATER

export default function testApi(url, data) {
    switch (url) {
        case "https://api.artjmartins.com/header-banner":
            return responses["header-banner"]
        
        case "https://api.artjmartins.com/home-products":
            return responses["home-products"]

        case "https://api.artjmartins.com/subscribe-newsletter":
            console.log(data)
            return;
        default:
            break;
    }
}


//HeaderBanner --> maximo 4 imagens
const responses = {
    "header-banner": [
        {
            "index": 0,
            "src": "https://www.templateface.com/prestashop/003/044/Gallery/modules/cbt_imageslider/views/img/sample-1.jpg",
            "title": "ESCULTURA",
            "redirect": "https://artjmartins.com/loja/escultura-de-um-frances1"
        },

        {
            "index": 1,
            "src": "https://www.templateface.com/prestashop/003/044/Gallery/modules/cbt_imageslider/views/img/sample-2.jpg",
            "title": "ESCULTURA",
            "redirect": "https://artjmartins.com/loja/escultura-de-um-frances2"
        },

        {
            "index": 2,
            "src": "https://www.templateface.com/prestashop/003/044/Gallery/modules/cbt_imageslider/views/img/sample-3.jpg",
            "title": "ESCULTURA",
            "redirect": "https://artjmartins.com/loja/escultura-de-um-frances3"
        },

    ],

    "home-products": {
        'MOST_RECENT': [
            {
                'index': 0,
                'mainImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/3/2/32-home_default.jpg",
                'subImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/2/2-home_default.jpg",
                'productId': 987547,
                'onSale': false,
                'redirect': "http://localhost:3000/cart/product/987547",
                'new': false,
                'discount': "-40%",
                'discountPrice': 12.60,
                'price': 34.54,
                'productName': "MOST_RECENT",
            },
            {
                'index': 1,
                'mainImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/3/2/32-home_default.jpg",
                'subImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/2/2-home_default.jpg",
                'productId': 987534,
                'onSale': false,
                'redirect': "http://localhost:3000/cart/product/987534",
                'new': false,
                'discount': "",
                'discountPrice':undefined,
                'price': 34.54,
                'productName': "Busto de marmore",
            },
            {
                'index': 2,
                'mainImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/3/2/32-home_default.jpg",
                'subImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/2/2-home_default.jpg",
                'productId': 987547,
                'onSale': true,
                'redirect': "http://localhost:3000/cart/product/987547",
                'new': true,
                'discount': "-40%",
                'discountPrice': 12.60,
                'price': 34.54,
                'productName': "Busto de pedra",
            },
            {
                'index': 3,
                'mainImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/3/2/32-home_default.jpg",
                'subImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/2/2-home_default.jpg",
                'productId': 997447,
                'onSale': false,
                'redirect': "http://localhost:3000/cart/product/987547",
                'new': false,
                'discount': "-40%",
                'discountPrice': 12.60,
                'price': 34.54,
                'productName': "Busto de Madeira",
            }
        ],

        'HIGHLIGHTED': [
            {
                'index': 0,
                'mainImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/3/2/32-home_default.jpg",
                'subImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/2/2-home_default.jpg",
                'productId': 987547,
                'onSale': true,
                'redirect': "http://localhost:3000/cart/product/987547",
                'new': false,
                'discount': "-40%",
                'discountPrice': 12.60,
                'price': 34.54,
                'productName': "HIGHLIGHTED",
            },
            {
                'index': 1,
                'mainImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/3/2/32-home_default.jpg",
                'subImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/2/2-home_default.jpg",
                'productId': 987534,
                'onSale': false,
                'redirect': "http://localhost:3000/cart/product/987534",
                'new': true,
                'discount': "",
                'discountPrice':undefined,
                'price': 34.54,
                'productName': "Busto de marmore",
            },
            {
                'index': 2,
                'mainImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/3/2/32-home_default.jpg",
                'subImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/2/2-home_default.jpg",
                'productId': 987547,
                'onSale': true,
                'redirect': "http://localhost:3000/cart/product/987547",
                'new': true,
                'discount': "-40%",
                'discountPrice': 12.60,
                'price': 34.54,
                'productName': "Busto de Madeira",
            },
            {
                'index': 3,
                'mainImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/3/2/32-home_default.jpg",
                'subImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/2/2-home_default.jpg",
                'productId': 997447,
                'onSale': false,
                'redirect': "http://localhost:3000/cart/product/987547",
                'new': false,
                'discount': "-40%",
                'discountPrice': 12.60,
                'price': 34.54,
                'productName': "Busto de Madeira",
            }
        ],

        'BEST_SELLERS': [
            {
                'index': 0,
                'mainImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/3/2/32-home_default.jpg",
                'subImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/2/2-home_default.jpg",
                'productId': 987547,
                'onSale': true,
                'redirect': "http://localhost:3000/cart/product/987547",
                'new': false,
                'discount': "-40%",
                'discountPrice': 12.60,
                'price': 34.54,
                'productName': "BEST_SELLERS",
            },
            {
                'index': 1,
                'mainImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/3/2/32-home_default.jpg",
                'subImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/2/2-home_default.jpg",
                'productId': 987534,
                'onSale': false,
                'redirect': "http://localhost:3000/cart/product/987534",
                'new': true,
                'discount': "",
                'discountPrice':undefined,
                'price': 34.54,
                'productName': "Busto de marmore",
            },
            {
                'index': 2,
                'mainImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/3/2/32-home_default.jpg",
                'subImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/2/2-home_default.jpg",
                'productId': 987547,
                'onSale': true,
                'redirect': "http://localhost:3000/cart/product/987547",
                'new': true,
                'discount': "-40%",
                'discountPrice': 12.60,
                'price': 34.54,
                'productName': "Busto de Madeira",
            },
            {
                'index': 3,
                'mainImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/3/2/32-home_default.jpg",
                'subImage': "https://www.templateface.com/prestashop/003/044/Gallery/img/p/2/2-home_default.jpg",
                'productId': 997447,
                'onSale': false,
                'redirect': "http://localhost:3000/cart/product/987547",
                'new': false,
                'discount': "-40%",
                'discountPrice': 12.60,
                'price': 34.54,
                'productName': "Busto de Madeira",
            }
        ]
    }
}

/*const itemsettings = {
    mainImage: "https://www.templateface.com/prestashop/003/044/Gallery/img/p/3/2/32-home_default.jpg",
    subImage: "https://www.templateface.com/prestashop/003/044/Gallery/img/p/2/2-home_default.jpg",
    productId: 987547,
    onSale: true,
    redirect: "http://localhost:3000/cart/product/987547",
    new: false,
    discount: "-40%",
    discountPrice: 12.60,
    price: 34.54,
    productName: "Busto de Madeira",
}*/