//Made by Rodrigo Martins

//Imports
import React from 'react';
import { Route, withRouter, Redirect, Switch, useLocation } from 'react-router-dom';

import "./MainPage.css"

import AppHeader from '../../components/AppHeader/index';
import HeaderBanner from "../../components/HeaderBanner/index"
import SubHeader from '../../components/SubHeader/index'
import Home from '../Home/home'
import BottomFooter from '../../components/BottomFooter/index'

function MainPage() {

    //Path location
    const location = useLocation().pathname;

    //Verifies if it is on the homepage if it is than show the Headerbanner
    var HeaderBanner_ = () => {return(null)};
    if(location === "/home" || location === "/") {
        HeaderBanner_ = HeaderBanner;
    }
    


    return(
        <div className="main-container">
            <AppHeader/>
            <HeaderBanner_/>
            <SubHeader/>

            <Switch>
                <Route
                exact
                path="/"
                render={() => {
                    return (
                        <Redirect to="/home"/>
                    )
                }}
                />
            </Switch>

            <Route exact path="/home" component={Home}/>


            <BottomFooter/>

        </div>
    );
}

export default withRouter(
    MainPage
);