//Made by Rodrigo Martins

//Imports
import React, { } from 'react';


import './index.css';


function StoreItem({settings}) {
    
    return(
        <div className="storeItem-main-container">
            <div className="storeItem-image-container">
                <a href={settings.redirect}>
                    <img src={settings.mainImage} alt={settings.productName} className="storeItem-image-main storeItem-image"/>
                    <img src={settings.subImage} alt={settings.productName} className="storeItem-image-sub storeItem-image" />
                </a>
                <ul className="storeItem-image-flag">
                    <li style={ settings.new === false  ? {visibility: 'hidden'} : null} className="storeItem-image-flag-new">NOVO</li>
                    <li style={ settings.onSale === false  ? {visibility: 'hidden'} : null} className="storeItem-image-flag-discount" >EM DESCONTO!</li>
                </ul>
                <span style={ settings.onSale === false  ? {visibility: 'hidden'} : null} className="storeItem-image-flag-discount-value">{settings.discount}</span>
            </div>
            <div className="storeItem-description-container" style={{textAlign: 'center'}}>
                <span className="storeItem-description-product-name">{settings.productName}</span>
                <div className="storeItem-description-product-price">
                    <span style={ settings.onSale === false  ? {color: " #000000", fontWeight: 800, fontSize: 17, textDecoration: "solid"} : null} className="storeItem-description-product-price-main">{settings.price + "€"}</span>
                    <span style={ settings.onSale === false  ? {visibility: 'hidden', position: 'absolute'} : null} className="storeItem-description-product-price-discountPrice">{settings.discountPrice + "€"}</span>
                </div>
            </div>
        </div>
    )

}


export default StoreItem;


/*Settings
{
    mainImage: "https://www.templateface.com/prestashop/003/044/Gallery/img/p/3/2/32-home_default.jpg",
    subImage: "https://www.templateface.com/prestashop/003/044/Gallery/img/p/2/2-home_default.jpg",
    redirect: "http://localhost:3000/cart/product/987547"
    productId: 987547,
    price: 34.54,
    productName: "Busto de Madeira"
    onSale: false,
    new: false,
    discount: -40%,
    discountPrice: 12.60,
}
*/