import { combineReducers } from "redux";

//Imports
import cartInfo from "./cartInfo";
import home from './home';

//Export default
export default combineReducers({
    CartInfo: cartInfo,
    Home: home
});