//Made by Rodrigo Martins

//Imports
import React, { useEffect, useState} from 'react';

import { useSelector, useDispatch } from "react-redux"; 

import './index.css';
import api from '../../services/api/test';

import StoreItem from "../../components/StoreItem/index"
import BottomFooter from '../../components/BottomFooter/index';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShippingFast, faMoneyBill, faHeadset } from '@fortawesome/free-solid-svg-icons';

//Home Screen
function Home() {
    //State
    const [apiData, setApiData] = useState(null);
    const [render, setRender] = useState(true);

    //Redux
    const reduxState = useSelector(state => state.Home);
    const reduxDispatch = useDispatch();

    //When componentwillmount
    useEffect(()=>{
        //Fetch the data on the api
        var response = null;
        async function apiCall() {
            try {
                response = await api("https://api.artjmartins.com/home-products")  
                setApiData(response)
            } catch (error) {
                setRender(false);
                return;
            }
        }
        apiCall();
    },[]);


    //Functions
    //Changes the HomeTab in redux
    function changeHomeTab(name) {
        if(name !== reduxState.homeTab) {
            reduxDispatch({ type: "CHANGE_HOME_TAB", payload: { name: name}})
        }
    }

   if(!render){return;}

    return(
        <div className="home-main-container">
            <div className="home-welcome-container container-space">
                <span className="home-welcome-title">Bem Vindo à Loja Online</span>
                <span className="home-welcome-description">Aqui poderás encontrar os nosso produtos na nossa loja online. 
                <p/> Dirige-te à aba <b>POR ENCOMENDA</b> para pedires um orçamento para os teus projetos. 
                 Contamos com uma vasta maquinaria para cumprir os seus projetos. 
                <p/> Para mais informações não hesiste em nos contactar! </span>
            </div>

            <div className="home-products-container container-space">
                <div className="home-products-tabs">
                    <ul>
                        <li onClick={()=>{changeHomeTab("MOST_RECENT")}} style={reduxState.homeTab === "MOST_RECENT" ? {color: "#d09c00", cursor: "default"} : null} >MAIS RECENTES</li>
                        <li onClick={()=>{changeHomeTab("BEST_SELLERS")}} style={reduxState.homeTab === "BEST_SELLERS" ? {color: "#d09c00", cursor: "default"} : null} >MAIS VENDIDOS</li>
                        <li onClick={()=>{changeHomeTab("HIGHLIGHTED")}} style={reduxState.homeTab === "HIGHLIGHTED" ? {color: "#d09c00", cursor: "default"} : null} >EM DESTAQUE</li>
                    </ul>
                </div>
            </div>
            <ul className="home-products-main-container container-space">
                {
                    (apiData !== null && apiData[reduxState.homeTab].map((product, index)=>{
                        return(
                            <li key={index} ><StoreItem settings={product}/></li>
                        )
                    }))
                }
            </ul>
            <div className="home-products-view-more-container container-space">
                <span onClick={()=>{window.location.href = "/shop/"}} style={{cursor: 'pointer'}} className="home-products-view-more" >MOSTRAR MAIS</span>
            </div>
            
            <div className="home-service-main-container container-space"> 
                <div className="home-service-money-back home-service-icon">
                    <FontAwesomeIcon icon={faMoneyBill} style={{position:'relative',alignSelf: 'center'}}/>
                    <span className="home-service-text">REEMBOLSO</span>
                </div>
                <div className="home-service-shipping home-service-icon">
                    <FontAwesomeIcon icon={faShippingFast} style={{position:'relative',alignSelf: 'center'}}/>
                    <span className="home-service-text">ENTREGAS WORLDWIDE</span>
                </div>
                <div className="home-service-support home-service-icon">
                    <FontAwesomeIcon icon={faHeadset} style={{position:'relative',alignSelf: 'center'}} />
                    <span className="home-service-text">SUPORTE 24H</span>
                </div>
            </div>
        </div>
    )
} 

export default Home;

