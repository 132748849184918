//Made by Rodrigo Martins

//Imports
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from "history";

import MainPage from './pages/MainPage/MainPage';
import reduxStore from './redux/reduxStore';

const rootElement = document.getElementById('root');
const routerHistory = createBrowserHistory();


//Render funtion
function Render(Component){
  	ReactDOM.render(
  		<React.StrictMode>
    		<Provider store={reduxStore} >
				<Router history={routerHistory} style={{margin:0, padding: 0}}>
					<Component style={{margin:0, padding: 0}}/>
				</Router>
			</Provider>
  		</React.StrictMode>,
  		rootElement
	);
}

//Starts the app by redering the mainPage that contains all the routing
Render(MainPage);
