//Made by Rodrigo Martins

//Imports
import React, { } from 'react';
import { useLocation } from 'react-router-dom'
import {isMobile} from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faUserCircle, faShoppingBasket } from '@fortawesome/free-solid-svg-icons';

//Redux
import { useSelector } from 'react-redux';

import logoImage from '../../assets/images/test_Logo.jpg';
import { Contactos } from '../../assets/mainConfig.json';

import './index.css';


//Diferent components
//Logo
const Logo = ()=> {
    return (
        <div className="subHeader-logo">
            <a href="/home">
                <img src={logoImage} alt="HOME"/>
            </a>
        </div>
    )
}

//Phone Icon
const PhoneIcon = () => {

    const onClick = () => {
        alert(Contactos.phoneNumber);
    }

    //If the device is an mobile then
    if(isMobile){
        return(
            <a className="subHeader-phone-icon" href={Contactos.phoneNumber}>
                <FontAwesomeIcon icon={faPhone} className="subHeader-phone-icon-1"/>
            </a>
        )
    }
    //If is on other device without being mobile
    return (
        
        <div className="subHeader-phone-icon" onClick={onClick}>
            <FontAwesomeIcon icon={faPhone} className="subHeader-phone-icon-1"/>
        </div>
    )
}


//Email Icon
function EmailIcon() {

    //onClick
    const onClick = () => {
        window.location.href = `mailto:${Contactos.geral}`;
    }

    return(
        <div className="subHeader-email-icon" >
            <FontAwesomeIcon onClick={onClick} icon={faEnvelope} className="subHeader-email-icon-1"/>
        </div>
    )
}


//Account Icon
function AccountIcon() {
    const location = useLocation().pathname

    //onClick
    const onClick = () => {
        if(location !== "/account") {
            window.location.href = "/account"
        }
    }

    return(
        <div className="subHeader-account-icon" >
            <FontAwesomeIcon style={location === "/account" ? {color: "#d09d00", pointerEvents: 'none'} : {}} onClick={onClick} icon={faUserCircle} className="subHeader-account-icon-1" />
        </div>
    )
}


//Shopping Cart Icon
function CartIcon({ numberItems }) {
    //Gets the current location
    const location = useLocation().pathname

    //onClick
    const onClick = () => {
        if(location !== "/cart") {
            window.location.href = "/cart"
        }
    }
    
    
    return(
        <div className="subHeader-cart-icon" >
            <FontAwesomeIcon style={location === "/cart" ? {color: "#d09d00", pointerEvents: 'none'} : {}} onClick={onClick} icon={faShoppingBasket} className="subHeader-cart-icon-1" />
            <div className="subHeader-cart-number-items">{numberItems}</div>
        </div>
    )
}


function SubHeader() {
    //State

    //Redux state
    const reduxState = useSelector(state => state.CartInfo)

    //Gets the location
    const location = useLocation().pathname


    return(
    
    <div className="subHeader-main-container" style={location === "/" || location === "/home" ? null : {marginTop: 51}}>
        <div className="container-space">

            <Logo/>
            <PhoneIcon/>
            <EmailIcon/>
            <CartIcon numberItems={reduxState.cartItems}/>
            <AccountIcon/>

        </div>
    </div>
    
    )
}


export default SubHeader;