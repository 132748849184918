//Made by Rodrigo Martins

//TODO delete testApi

//Imports
import React, { useEffect, useState } from 'react';
import api from '../../services/api/test';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp } from '@fortawesome/free-solid-svg-icons'

import './index.css' 

function HeaderBanner() {
    //States
    const [render, setRender] = useState(true);
    const [images, setImages] = useState(null);
    const [currentImage, setCurrentImage] = useState(1);

    //Component did mount
    useEffect(() => {
        //Fetches data on the api
        let response = null;
        async function apiCall() {
            try {
                response = api("https://api.artjmartins.com/header-banner")
                setImages(response);
            } catch (error) {
                setRender(false);
            }
        }

        apiCall();
    },[]);


    //If dosent has any data dosent render the headerBanner
    if (!Array.isArray(images) || images.length <= 0 || !render) {
        return null;
    }
    const length = images.length;


    //Funtions
    const nextImage = () => {
        setCurrentImage(currentImage === length - 1 ? 0 : currentImage + 1);
    }

    const prevImage = () => {
        setCurrentImage(currentImage === 0 ? length - 1 : currentImage - 1);
    }

    //Main return
    return(

        <div className="headerBanner-main-container">
            <FontAwesomeIcon onMouseDown={nextImage} className="headerBanner-slider-button-right" icon={faSortUp} rotation={90}/>
            <FontAwesomeIcon onMouseDown={prevImage} className="headerBanner-slider-button-left" icon={faSortUp}  rotation={270}/>
            <ul className="headerBanner-slider-list">
                {images.map((image, index) => {
                    return(
                        <li onMouseDown={()=>{setCurrentImage(index)}} key={index} className={index === currentImage ? "headerBanner-slider-list-button headerBanner-slider-list-button-active" : "headerBanner-slider-list-button"} ></li>
                )})}
            </ul>
            {images.map((image, index) => {
                return(
                    <a key={index} title={image.title} href={image.redirect} className={index === currentImage ? "headerBanner-image headerBanner-active-image" : "headerBanner-image"} >
                        {index === currentImage && (
                            <img src={image.src} alt={image.title} />
                        )}
                    </a>
                )})}
        </div>

    )
}

export default HeaderBanner