//by Rodrigo Martins

import { createActions, createReducer } from "reduxsauce";

//Inicial state
const INITIAL_STATE = [];

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  changeHomeTab: ["name"],
});



//HANDLERS -------------------------------------------
const changeHomeTab = (state = INITIAL_STATE, action) => {
    return{...state, homeTab: action.payload.name }
};



//HANDLERS -------------------------------------------
export default createReducer(INITIAL_STATE, {
  [Types.CHANGE_HOME_TAB]: changeHomeTab
});
