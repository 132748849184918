//Made by Rodrigo Martins

//Imports
//Libs
import { createStore } from 'redux'

//Local Files
import reducers from './ducks/index'
import storeMap from './reduxMap';


//Midleware
//const middleware = null;
//Creates redux store
const store = createStore(reducers, storeMap);

export default store;