//Made by Rodrigo Martins

//Imports
import React, { useState } from 'react';
import { MenuButtonWideFill, XSquare } from 'react-bootstrap-icons';
import { useLocation } from 'react-router-dom';

import './index.css' 


//AppHeader  funtion
function AppHeader() {
    //State
    const [mobileBox, setMobileBox] = useState("hidden");

    //Funtions
    const location = useLocation().pathname;
    
    //Mobile
    const mobileOpenMenu = () => {
        setMobileBox("visible")
    }

    const mobileCloseMenu = () => {
        setMobileBox("hidden")
    }

    return(
        <div className="appheader-main-container">
                    <div className="appheader-mobile">
                            <span className={"appheader-mobile-menu"}>
                                <MenuButtonWideFill onMouseDown={mobileOpenMenu} color="white" size={16} className="appheader-mobile-menu-text-button"/>
                                <span className="appheader-mobile-menu-text">MENU</span>
                            </span>

                            <span className="appheader-mobile-menu-close" style={{visibility: mobileBox}}>
                                    <XSquare onMouseDown={mobileCloseMenu} color="white" size={16} className="appheader-mobile-menu-text-button"/>
                                    <span className="appheader-mobile-menu-text">MENU</span>
                            </span>
                            
                            <span className="appheader-white-box"></span>

                            <div className="appheader-mobile-menu-box" style={{visibility: mobileBox}}>
                                <ul className="appheader-mobile-menu-box-ul">
                                    <li>
                                        <a className={location === "/home" || location === "/" ? "active": ""} href="/home">HOME</a>
                                    </li>
                                    <li>
                                        <a className={location === "/loja" ? "active": ""} href="/loja">LOJA</a>
                                    </li>
                                    <li>
                                        <a className={location === "/materiais" ? "active": ""} href="/materiais">MATERIAIS</a>
                                    </li>
                                    <li>
                                        <a className={location === "/maquinas" ? "active": ""} href="/maquinas">MAQUINAS</a>
                                    </li>
                                    <li>
                                        <a className={location === "/por-encomenda" ? "active": ""} href="/por-encomenda">POR ENCOMENDA</a>
                                    </li>
                                    <li>
                                        <a className={location === "/contactos" ? "active": ""} href="/contactos">CONTACTOS</a>
                                    </li>
                                    <li>
                                        <a className={location === "/mais" ? "active": ""} href="/mais">MAIS</a>
                                    </li>
                                </ul>
                            </div>
                    </div>


                    <ul className="appheader-ul">
                            <li>
                                <a className={location === "/home" || location === "/" ? "active": ""} href="/home">HOME</a>
                            </li>
                            <li>
                                <a className={location === "/loja" ? "active": ""} href="/loja">LOJA</a>
                            </li>
                            <li>
                                <a className={location === "/materiais" ? "active": ""} href="/materiais">MATERIAIS</a>
                            </li>
                            <li>
                                <a className={location === "/maquinas" ? "active": ""} href="/maquinas">MAQUINAS</a>
                            </li>
                            <li>
                                <a className={location === "/por-encomenda" ? "active": ""} href="/por-encomenda">POR ENCOMENDA</a>
                            </li>
                            <li>
                                <a className={location === "/contactos" ? "active": ""} href="/contactos">CONTACTOS</a>
                            </li>
                            <li>
                                <a className={location === "/mais" ? "active": ""} href="/mais">MAIS</a>
                            </li>
                </ul>
        </div>
    )
}

export default AppHeader;