//Made by Rodrigo Martins

//Imports
import React, { useState } from 'react';
import api from '../../services/api/test';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhone, faAddressCard, faBox, faEnvelopeOpen, faShippingFast, faMoneyBill } from '@fortawesome/free-solid-svg-icons';

import { Contactos } from '../../assets/mainConfig.json'
import "./index.css"


function BottomFooter() {
    //States
    const [emailInput, setEmailInput] = useState("");
    const [validInputEmail, setValidInputEmail] = useState(false);

    //Funtions
    function inputEmail(event) {
        setEmailInput(event.target.value);
        // don't remember from where i copied this code, but this works.
        var pattern = new RegExp(/^(([^<>()/[\]\\.,;:\s@"]+(\.[^<>()/[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        (pattern.test(event.target.value) ? setValidInputEmail(true) : setValidInputEmail(false));
    }

    async function subscribeButton() {
        if(validInputEmail) {
            try {
                await api("https://api.artjmartins.com/subscribe-newsletter", {email: emailInput}) ;
            } catch (error) {
                return;
            }
        }
    }

    return(
        <div className="bottomFooter-main-container container-space">
            <div className="bottomFooter-newsletter-container container-space">
                <div className="bottomFooter-newsletter-text">
                    <span className="bottomFooter-newsletter-text-title">SUBSCREVE AGORA AO NOSSO NEWSLETTER</span>
                    <span className="bottomFooter-newsletter-text-description">Subscreve agora para obter os melhores descontes, vales e ofertas.</span>
                </div>
                <div className="bottomFooter-newsletter-subcribe" > 
                    <input value={emailInput} onChange={inputEmail} type="email" placeholder="O seu endereço de email"  className="bottomFooter-newsletter-subcribe-input"/>
                    <span onClick={subscribeButton} style={validInputEmail ? null : { cursor: 'not-allowed', backgroundColor: "#d09d00", color: "#ffffff" }} className="bottomFooter-newsletter-subcribe-button">SUBSCREVER</span>
                </div>
            </div>

            <div className="bottomFooter-footer-main-cointaner container-space">
                <div className="bottomFooter-footer-container">
                    <div className="bottomFooter-footer-store-info bottomFooter-footer-sub-containers">
                        <a href="/contactos"><span className="bottomFooter-footer-store-info-title">INFORMAÇÕES DA LOJA</span></a>
                        <ul>
                            <li><FontAwesomeIcon icon={faMapMarkerAlt} className="bottomFooter-footer-store-info-icon"/> Rua Maria Matilde Pratas 11,<br/>‎‎‎‎‎‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‏‏‎ ‎‎Aveiras de Cima,<br/>‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‎‎‎‎‎‎Portugal</li>
                            <li><FontAwesomeIcon icon={faEnvelope} className="bottomFooter-footer-store-info-icon"/> {Contactos.geral}</li>
                            <li><FontAwesomeIcon icon={faPhone} className="bottomFooter-footer-store-info-icon"/> {Contactos.phoneNumber}</li>
                        </ul>
                    </div>

                    <div className="bottomFooter-footer-account bottomFooter-footer-sub-containers">
                        <a href="/account"><span className="bottomFooter-footer-account-title">SUA CONTA</span></a>
                        <ul>
                            <li><FontAwesomeIcon icon={faAddressCard} className="bottomFooter-footer-account-icon"/><a href="/account/personal-info"> Informação pessoal</a></li>
                            <li><FontAwesomeIcon icon={faBox} className="bottomFooter-footer-account-icon"/><a href="/account/orders"> Encomendas</a></li>
                            <li><FontAwesomeIcon icon={faEnvelopeOpen} className="bottomFooter-footer-account-icon"/><a href="/account/budgets"> Orçamentos</a></li>
                        </ul>
                    </div>

                    <div className="bottomFooter-footer-more bottomFooter-footer-sub-containers">
                        <a href="/mais"><span className="bottomFooter-footer-more-title">MAIS</span></a>
                        <ul>
                            <li><FontAwesomeIcon icon={faShippingFast} className="bottomFooter-footer-more-icon"/><a href="/mais"> Envio e Transportadoras</a></li>
                            <li><FontAwesomeIcon icon={faMoneyBill} className="bottomFooter-footer-more-icon"/><a href="/mais"> Reembolsos</a></li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default BottomFooter;